'use client'
"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../types/dist/index.js
var require_dist = __commonJS({
  "../types/dist/index.js"(exports, module2) {
    "use strict";
    "use client";
    var __defProp2 = Object.defineProperty;
    var __getOwnPropDesc2 = Object.getOwnPropertyDescriptor;
    var __getOwnPropNames2 = Object.getOwnPropertyNames;
    var __hasOwnProp2 = Object.prototype.hasOwnProperty;
    var __export2 = (target, all) => {
      for (var name in all)
        __defProp2(target, name, { get: all[name], enumerable: true });
    };
    var __copyProps2 = (to, from, except, desc) => {
      if (from && typeof from === "object" || typeof from === "function") {
        for (let key of __getOwnPropNames2(from))
          if (!__hasOwnProp2.call(to, key) && key !== except)
            __defProp2(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc2(from, key)) || desc.enumerable });
      }
      return to;
    };
    var __toCommonJS2 = (mod) => __copyProps2(__defProp2({}, "__esModule", { value: true }), mod);
    var src_exports2 = {};
    __export2(src_exports2, {
      Actions: () => Actions3,
      SPECIALITIES: () => SPECIALITIES,
      VERTICALS: () => VERTICALS
    });
    module2.exports = __toCommonJS2(src_exports2);
    var SPECIALITIES = [
      "gyn",
      "hud",
      "pediatrician",
      "fysio",
      "kiro",
      "midwife",
      "PT",
      "plastikk"
    ];
    var Actions3 = /* @__PURE__ */ ((Actions22) => {
      Actions22["SelectVertical"] = "select_vertical_v2";
      Actions22["SelectSpeciality"] = "select_speciality_v2";
      Actions22["SelectLocation"] = "select_location_v2";
      Actions22["SelectClinic"] = "select_clinic_v2";
      Actions22["SelectPractitioner"] = "select_practitioner_v2";
      Actions22["SkipSelectPractitioner"] = "skip_select_practitioner_v2";
      Actions22["OpenPractitionerInfo"] = "open_practitioner_info";
      Actions22["OpenMap"] = "open_map_v2";
      Actions22["SelectTime"] = "select_time_v2";
      Actions22["Permissions"] = "permissions_v2";
      Actions22["SelectPersonalDetails"] = "select_personal_details_v2";
      Actions22["Confirm"] = "confirm_booking_v2";
      Actions22["CompletedBooking"] = "completed_booking_v2";
      Actions22["ClickedBookAgain"] = "clicked_book_again_v2";
      Actions22["SelectDateButtonDisabled"] = "select_date_button_disabled";
      Actions22["ClickAppStore"] = "click_app_store";
      Actions22["ClickPlayStore"] = "click_play_store";
      Actions22["ClickNextAvailable"] = "click_next_available";
      Actions22["ExitFlow"] = "exit_flow_v2";
      Actions22["GoBack"] = "go_back_v2";
      Actions22["AddPartner"] = "add_partner_v2";
      Actions22["BookAppointmentError"] = "book_appointment_error_v2";
      Actions22["ClinicSortOptionClicked"] = "option_clicked_v2";
      Actions22["CountryCodePicked"] = "country_code_picked_v2";
      Actions22["CountryCodePressed"] = "country_code_pressed_v2";
      Actions22["DeleteCard"] = "delete_card_v2";
      Actions22["SelectAgreementClicked"] = "select_agreement_clicked";
      Actions22["SelectAgreementSuccess"] = "select_agreement_success";
      Actions22["WithoutAgreementClicked"] = "without_agreement_clicked";
      Actions22["ChangeAgreementClicked"] = "change_agreement_clicked";
      Actions22["ModalChangeAgreementClicked"] = "modal_change_agreement_clicked";
      Actions22["ModalWithoutAgreementClicked"] = "modal_without_agreement_clicked";
      Actions22["ModalCancelAppointmentClicked"] = "modal_cancel_appointment_clicked";
      Actions22["SelectCountryClicked"] = "select_country_clicked";
      Actions22["SearchCountryClicked"] = "search_country_clicked";
      Actions22["SelectCountrySuccess"] = "select_country_success";
      Actions22["SelectCountryInvalid"] = "select_country_invalid";
      Actions22["ChangeCountryClicked"] = "change_country_clicked";
      Actions22["InitializePayment"] = "initialize_payment_v2";
      Actions22["InitializedPayment"] = "initialized_payment_v2";
      Actions22["InitializePaymentError"] = "initialize_payment_error_v2";
      Actions22["PaymentCompleted"] = "payment_completed_v2";
      Actions22["PaymentError"] = "payment_error_v2";
      Actions22["VippsRedirectInitialized"] = "vipps_redirect_initialized_v2";
      Actions22["VippsRedirectFailed"] = "vipps_redirect_failed_v2";
      Actions22["VippsPaymentCancelled"] = "vipps_payment_cancelled_v2";
      Actions22["VippsPaymentCompleted"] = "vipps_payment_completed_v2";
      Actions22["UpdateUserPhone"] = "update_user_phone_v2";
      Actions22["VideoInfoClicked"] = "video_info_clicked_v2";
      Actions22["NewsletterAccept"] = "newsletter_accepted";
      Actions22["NewsletterReject"] = "newsletter_rejected";
      Actions22["NewsletterMissing"] = "newsletter_email_missing";
      Actions22["NewsletterSubmitted"] = "newsletter_email_submitted";
      Actions22["CreateMembershipError"] = "create_membership_error";
      Actions22["HomeAddPartners"] = "home_insurance&discount_add";
      Actions22["HomeAddPartnersSelected"] = "home_insurance&discount_add_selected";
      Actions22["HomeAddPartnersSelectedAdded"] = "home_insurance&discount_add_selected_added";
      Actions22["HomeAddPartnersSelectedError"] = "home_insurance&discount_add_selected_error";
      Actions22["HomeAddPartnersSelectedRequest"] = "home_insurance&discount_add_selected_request";
      Actions22["HomeContact"] = "home_contact";
      Actions22["HomeOnboarding"] = "home_onboarding";
      Actions22["HomePartnersExisting"] = "home_insurance&discount_existing";
      Actions22["HomePartnersExistingError"] = "home_insurance&discount_existing_error";
      Actions22["HomePartnersExistingRemove"] = "home_insurance&discount_existing_remove";
      Actions22["HomePartnersExistingRequestUpdate"] = "home_insurance&discount_existing_request_update";
      Actions22["HomePartnersExistingUpdated"] = "home_insurance&discount_existing_updated";
      Actions22["HomeReturn"] = "home_return";
      Actions22["HomeScreen"] = "home_screen";
      Actions22["HomeVideoHelp"] = "home_video_help";
      Actions22["UpdateAppShown"] = "update_app_shown";
      Actions22["UpdateAppPressed"] = "update_app_pressed";
      Actions22["ExpiredMembershipRemoved"] = "expired_membership_removed";
      Actions22["ExpiredMembershipRenewed"] = "expired_membership_renewed";
      Actions22["LoginButtonPressed"] = "login_button_pressed";
      Actions22["LoginCancelButtonPressed"] = "login_cancel_button_pressed";
      Actions22["LoginCompleted"] = "login_completed";
      Actions22["LoginError"] = "login_error";
      Actions22["LoginFinished"] = "login_finished";
      Actions22["LoginSetPinCode"] = "login_setting_pin_code";
      Actions22["LogoutCompleted"] = "logout_completed";
      Actions22["LogoutError"] = "logout_error";
      Actions22["LogoutInitiated"] = "logout_initiated";
      Actions22["LogoutProceed"] = "logout_proceed";
      Actions22["LogoutPromptAnswered"] = "logout_prompt_answered";
      Actions22["ClickExorLive"] = "click_exor_live";
      Actions22["GoToExorLive"] = "go_to_exor_live";
      Actions22["CancelExorLive"] = "cancel_exor_live";
      Actions22["LoginForgotPin"] = "login_forgot_pin";
      Actions22["LoginPinAndBiometricsSuccess"] = "login_pin_and_biometrics_success";
      Actions22["MotimateCreateUserStart"] = "motimate_create_user_start";
      Actions22["MotimateCreateUserSubmit"] = "motimate_create_user_submit";
      Actions22["MotimateCreateUserError"] = "motimate_create_user_error";
      Actions22["MotimateCreateUserCompleted"] = "motimate_create_user_completed";
      Actions22["MotimateCreateUserCanceled"] = "motimate_create_user_canceled";
      Actions22["MotimateLinkClicked"] = "motimate_link_clicked";
      Actions22["PermissionRequested"] = "permission_requested";
      Actions22["PermissionsGranted"] = "permissions_granted";
      Actions22["PermissionsMissing"] = "permissions_missing";
      Actions22["PermissionsOverlay"] = "permissions_overlay";
      Actions22["PermissionsRedirectToSettings"] = "permissions_open_settings";
      Actions22["PermissionsRedirectToSettingsFailed"] = "permissions_open_settings_failed";
      Actions22["ProfileBiometrics"] = "profile_pin&biometrics";
      Actions22["ProfileBiometricsChangePinCode"] = "profile_pin&biometrics_pincode";
      Actions22["ProfileBiometricsSetBiometrics"] = "profile_pin&biometrics_biometrics_activate";
      Actions22["ProfileBiometricsUnsetBiometrics"] = "profile_pin&biometrics_biometrics_deactivate";
      Actions22["ProfileContact"] = "profile_contact";
      Actions22["ProfileDetailsCountryCodePicked"] = "profile_personal_information_country_code_picked";
      Actions22["ProfileDetailsCountryCodePressed"] = "profile_personal_information_country_code_pressed";
      Actions22["ProfileInfoSaveError"] = "Profile_Save_Error";
      Actions22["ProfileInfoSaved"] = "Saved_Personal_Info";
      Actions22["ProfileOnboarding"] = "profile_onboarding";
      Actions22["ProfilePartners"] = "home_insurance&discount";
      Actions22["ProfilePaymentCard"] = "profile_payment_card";
      Actions22["ProfilePermissions"] = "profile_permissions";
      Actions22["ProfileReturn"] = "profile_return";
      Actions22["ProfileSavedDetails"] = "profile_personal_information_saved";
      Actions22["ProfileScreen"] = "profile_screen";
      Actions22["ProfileVideoHelp"] = "profile_video_help";
      Actions22["VersionDeprecationWarning"] = "version_deprecation_warning";
      Actions22["VersionDeprecationWarningAction"] = "version_deprecation_warning_action";
      Actions22["VideoAnsweredCall"] = "video_answered_call";
      Actions22["VideoAnsweredCallOld"] = "Answered_Video_Call";
      Actions22["VideoCallEnded"] = "video_call_ended";
      Actions22["VideoConnected"] = "video_connected";
      Actions22["VideoConnecting"] = "video_connecting";
      Actions22["VideoDeclined"] = "video_declined";
      Actions22["VideoDisconnected"] = "video_disconnected";
      Actions22["VideoEndedConsultationOld"] = "Ended_Video_Consultation";
      Actions22["VideoEnteredScreen"] = "video_entered_screen";
      Actions22["VideoError"] = "video_error";
      Actions22["VideoReceivedCall"] = "video_received_call";
      Actions22["VideoRecievedCallOld"] = "Recieved_Video_Call";
      Actions22["VideoSettingsChangeMicrophone"] = "video_settings_change_microphone";
      Actions22["VideoSettingsFetchingActiveAudio"] = "video_settings_fetching_active_audio";
      Actions22["VideoSettingsFlipCamera"] = "video_settings_flip_camera";
      Actions22["VideoSettingsToggleMuteMicrophone"] = "video_settings_toggle_mute_microphone";
      Actions22["VideoSummaryDisplayed"] = "video_displayed_summary";
      Actions22["VideoTryingToConnect"] = "video_trying_to_connect";
      Actions22["ApiError"] = "Api_Error";
      Actions22["AppError"] = "app_error";
      Actions22["BookInClinic"] = "book_in_clinic";
      Actions22["ClickedChooseMedicalIssue"] = "clicked_choose_medical_issue";
      Actions22["SelectMedicalIssue"] = "select_medical_issue";
      Actions22["ChangeMedicalIssue"] = "change_medical_issue";
      Actions22["ClickedReadMoreVideo"] = "clicked_read_more_video";
      Actions22["ClickedClosedInfoCard"] = "clicked_closed_info_card";
      Actions22["MedicalInfoCardTreatable"] = "medical_info_card_treatable";
      Actions22["MedicalInfoCardClickedContinueVideo"] = "medical_info_card_clicked_continue_video";
      Actions22["MedicalInfoCardClickedBookClinic"] = "medical_info_card_clicked_book_clinic";
      Actions22["ClickedAddChild"] = "clicked_add_child";
      Actions22["AddedChild"] = "add_child_success";
      Actions22["AddChildClosed"] = "add_child_closed";
      Actions22["ErrorAddingChild"] = "add_child_error";
      Actions22["SelectedChild"] = "select_child";
      Actions22["SelectedSelf"] = "select_myself";
      Actions22["DeletedChild"] = "deleted_child";
      Actions22["ClickedPhysicalGuide"] = "clicked_physical_guide";
      Actions22["CloseVideoModal"] = "close_video_modal";
      Actions22["OpenVideoModal"] = "open_video_modal";
      Actions22["FutureDateError"] = "web_booking_profilePersonalInformation_futureDateError";
      Actions22["PastDateError"] = "web_booking_profilePersonalInformation_pastDateError";
      Actions22["PhoneVerificationError"] = "phoneVerificationError";
      Actions22["PhoneVerificationSuccess"] = "phoneVerificationSuccess";
      Actions22["PhoneVerificationSupport"] = "phoneVerificationSupport";
      return Actions22;
    })(Actions3 || {});
    var VERTICALS = [
      "covid",
      "dermatologist",
      "general",
      "mental-health",
      "eye-doctor",
      "gynecologist",
      "physical",
      "psychology",
      "specialist"
    ];
  }
});

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Accordion: () => Accordion_default,
  Alert: () => Alert,
  AnimatedStep: () => AnimatedStep,
  AppLinks: () => AppLinks,
  ButtonBadge: () => ButtonBadge,
  CustomPhoneInput: () => CustomPhoneInput,
  DateButton: () => DateButton,
  DateScrollButtons: () => DateButtonsScroll,
  DateSkeleton: () => DateSkeleton,
  Divider: () => Divider,
  DrDropinLogo: () => DrDropinLogo,
  HighlightedIcon: () => HighlightedIcon,
  InputError: () => InputError,
  Link: () => Link,
  LocaleSwitcher: () => LocaleSwitcher,
  Map: () => Map,
  ModalProvider: () => ModalProvider,
  Notification: () => Notification,
  RightArrowButton: () => RightArrowButton,
  RoundedButton: () => RoundedButton,
  SelectPills: () => SelectPills,
  ServiceCard: () => ServiceCard,
  SpinnerWrapper: () => SpinnerWrapper_default,
  StepButton: () => StepButton,
  StepListItem: () => StepListItem,
  StepWrapper: () => StepWrapper,
  TermsOfService: () => TermsOfService,
  TextButton: () => TextButton,
  Tooltip: () => Tooltip,
  TooltipContent: () => TooltipContent,
  TooltipTrigger: () => TooltipTrigger,
  useModal: () => useModal
});
module.exports = __toCommonJS(src_exports);

// src/components/Accordion/Accordion.tsx
var import_react5 = require("react");

// src/components/Accordion/AccordionButton.tsx
var import_classnames = __toESM(require("classnames"));
var import_framer_motion = require("framer-motion");
var import_react2 = require("react");

// src/components/Accordion/AccordionContext.tsx
var import_react = require("react");
var AccordionContext = (0, import_react.createContext)({
  activeItem: "",
  changeSelectedItem: () => {
  }
});
var AccordionContext_default = AccordionContext;
var useAccordionContext = () => {
  const context = (0, import_react.useContext)(AccordionContext);
  if (!context) {
    throw new Error("Error in creating the context");
  }
  return context;
};

// src/components/Accordion/renderChildrenWithProps.ts
function renderChildrenWithProps(children, props) {
  if (typeof children === "function")
    return children(props);
  return children;
}

// src/components/Accordion/AccordionButton.tsx
var import_jsx_runtime = require("react/jsx-runtime");
function AccordionButton({
  label,
  children,
  className,
  onClick
}) {
  const { changeSelectedItem, activeItem } = useAccordionContext();
  const isSelected = activeItem === label;
  const handleClick = (0, import_react2.useCallback)(() => {
    if (onClick)
      onClick(label);
    else
      changeSelectedItem(isSelected ? "" : label);
  }, [changeSelectedItem, isSelected, label, onClick]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    import_framer_motion.motion.button,
    {
      className: (0, import_classnames.default)(
        {
          "bg-accent": isSelected,
          "bg-white": !isSelected
        },
        "hover:bg-accent flex w-full items-center justify-between gap-2 rounded-xl border-b-[1px] px-6 py-5",
        className
      ),
      onClick: handleClick,
      children: renderChildrenWithProps(children, { isSelected })
    }
  );
}

// src/components/Accordion/AccordionItem.tsx
var import_react3 = require("react");
var import_jsx_runtime2 = require("react/jsx-runtime");
var AccordionItem = ({
  children,
  label,
  as,
  ...restProps
}) => {
  const Component = as || "div";
  const itemChildren = import_react3.Children.map(children, (child) => {
    const item = child;
    return (0, import_react3.cloneElement)(item, {
      ...item.props,
      label
    });
  });
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(Component, { ...restProps, children: itemChildren });
};
var AccordionItem_default = AccordionItem;

// src/components/Accordion/AccordionPanel.tsx
var import_classnames2 = __toESM(require("classnames"));
var import_framer_motion2 = require("framer-motion");
var import_react4 = require("react");
var import_jsx_runtime3 = require("react/jsx-runtime");
function AccordionPanel({ children, label }) {
  const controls = (0, import_framer_motion2.useAnimation)();
  const { activeItem } = useAccordionContext();
  const variants2 = {
    expanded: {
      height: "unset",
      transition: {
        duration: 0.125
      }
    },
    collapsed: {
      height: "0px",
      transition: {
        duration: 0.25
      }
    }
  };
  (0, import_react4.useEffect)(() => {
    if (activeItem === label) {
      controls.start("expanded");
    } else {
      controls.start("collapsed");
    }
  }, [controls, activeItem, label]);
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
    import_framer_motion2.motion.div,
    {
      initial: "collapsed",
      className: (0, import_classnames2.default)(
        { "overflow-x-hidden overflow-y-hidden": activeItem !== label },
        "z-0 mx-3 overflow-hidden rounded-b-xl bg-white"
      ),
      animate: controls,
      transition: { duration: 0.33 },
      variants: variants2,
      children: renderChildrenWithProps(children, { isSelected: activeItem === label })
    }
  );
}

// src/components/Accordion/Accordion.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var Accordion = ({
  as,
  defaultActiveItem = "",
  children,
  ...restProps
}) => {
  const [activeItem, setActiveItem] = (0, import_react5.useState)(defaultActiveItem);
  const changeSelectedItem = (0, import_react5.useCallback)(
    (value) => {
      if (activeItem !== value)
        setActiveItem(value);
    },
    [setActiveItem, activeItem]
  );
  const Component = as || "div";
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(AccordionContext_default.Provider, { value: { activeItem, changeSelectedItem }, children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Component, { ...restProps, children }) });
};
Accordion.Item = AccordionItem_default;
Accordion.Panel = AccordionPanel;
Accordion.Button = AccordionButton;
var Accordion_default = Accordion;

// src/components/Alert.tsx
var import_jsx_runtime5 = require("react/jsx-runtime");
function Alert({ title, message }) {
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
    "div",
    {
      className: "z-200 top-0 flex w-full justify-center px-4 py-3 pb-5",
      style: styles.alert,
      children: /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("div", { className: "max-w-lg", children: [
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("h3", { className: "mt-0 font-medium", children: title }),
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("h5", { className: "mt-1 text-xs", children: message })
      ] })
    }
  );
}
var styles = {
  alert: {
    color: "#815924",
    backgroundColor: "#FFEBD2"
  }
};

// src/components/AppLinks.tsx
var import_types = __toESM(require_dist());
var import_image = __toESM(require("next/image"));

// src/utils/getMobileOS.ts
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
}

// src/components/AppLinks.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
function AppLinks({ trackingFunction }) {
  const mobileOS = getMobileOperatingSystem();
  function trackAppStoreClick() {
    if (trackingFunction) {
      trackingFunction(import_types.Actions.ClickAppStore);
    }
  }
  function trackPlayStoreClick() {
    if (trackingFunction) {
      trackingFunction(import_types.Actions.ClickPlayStore);
    }
  }
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("div", { className: "flex justify-around gap-4", children: [
    ["iOS", "unknown"].includes(mobileOS) ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      "a",
      {
        href: "https://apps.apple.com/no/app/dr-dropin/id1467608455?ls=1",
        className: "h-15 relative w-full md:w-1/2 md:max-w-[200px]",
        target: "_blank",
        rel: "noopener noreferrer",
        onClick: () => trackAppStoreClick(),
        children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
          import_image.default,
          {
            src: "/illustrations/appStoreiOS.png",
            alt: "Download on the App Store",
            fill: true,
            sizes: "100vw",
            style: {
              objectFit: "contain"
            }
          }
        )
      }
    ) : null,
    ["Android", "unknown"].includes(mobileOS) ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      "a",
      {
        href: "https://play.google.com/store/apps/details?id=com.drdropin",
        target: "_blank",
        rel: "noopener noreferrer",
        className: "h-15 relative w-full md:w-1/2 md:max-w-[200px]",
        onClick: () => trackPlayStoreClick(),
        children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
          import_image.default,
          {
            src: "/illustrations/appStoreAndroid.png",
            alt: "Get it on Google Play",
            fill: true,
            sizes: "100vw",
            style: {
              objectFit: "contain"
            }
          }
        )
      }
    ) : null
  ] });
}

// src/components/ButtonBadge.tsx
var import_classnames3 = __toESM(require("classnames"));
var import_jsx_runtime7 = require("react/jsx-runtime");
function ButtonBadge({ title, className }) {
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
    "div",
    {
      className: (0, import_classnames3.default)(
        "bg-warning-light absolute -right-2 -top-1.5 w-fit rounded-full px-3 py-1 text-center text-xs shadow-sm transition-all",
        className
      ),
      children: title
    }
  );
}

// src/components/CustomPhoneInput/CustomPhoneInput.tsx
var import_classnames5 = __toESM(require("classnames"));
var import_react7 = require("react");
var import_react_phone_number_input2 = require("react-phone-number-input");
var import_input = __toESM(require("react-phone-number-input/input"));

// src/components/InputError.tsx
var import_theseus = require("@drdropin-tech/theseus");
var import_jsx_runtime8 = require("react/jsx-runtime");
function InputError({ isDirty, errors }) {
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(import_jsx_runtime8.Fragment, { children: [
    isDirty && !errors && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: "absolute inset-y-0 right-5 flex items-center", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      import_theseus.Icon,
      {
        name: "CheckCircle",
        appearance: "primary",
        className: "animated-checkmark"
      }
    ) }),
    errors && /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: "absolute inset-y-0 right-5 flex items-center", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_theseus.Icon, { name: "AlertOctagon", className: "stroke-error" }) })
  ] });
}

// src/components/CustomPhoneInput/CountrySelect.tsx
var import_shared = require("@booking/shared");
var import_theseus2 = require("@drdropin-tech/theseus");
var import_classnames4 = __toESM(require("classnames"));
var import_fuse = __toESM(require("fuse.js"));
var import_react6 = require("react");
var import_react_phone_number_input = require("react-phone-number-input");

// src/components/CustomPhoneInput/countryTranslations.json
var countryTranslations_default = {
  AF: "Afghanistan",
  AX: "\xC5land Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua & Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia & Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  BQ: "Caribbean Netherlands",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo - Brazzaville",
  CD: "Congo - Kinshasa",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "C\xF4te d\u2019Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Cura\xE7ao",
  CY: "Cyprus",
  CZ: "Czechia",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard & McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong SAR China",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao SAR China",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar (Burma)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  KP: "North Korea",
  MK: "North Macedonia",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territories",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn Islands",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "R\xE9union",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  WS: "Samoa",
  SM: "San Marino",
  ST: "S\xE3o Tom\xE9 & Pr\xEDncipe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia & South Sandwich Islands",
  KR: "South Korea",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  BL: "St. Barth\xE9lemy",
  SH: "St. Helena",
  KN: "St. Kitts & Nevis",
  LC: "St. Lucia",
  MF: "St. Martin",
  PM: "St. Pierre & Miquelon",
  VC: "St. Vincent & Grenadines",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard & Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad & Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks & Caicos Islands",
  TV: "Tuvalu",
  UM: "U.S. Outlying Islands",
  VI: "U.S. Virgin Islands",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VA: "Vatican City",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis & Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};

// src/components/CustomPhoneInput/CountrySelect.tsx
var import_jsx_runtime9 = require("react/jsx-runtime");
var CountrySelect = ({
  options,
  selected,
  onChange,
  i18nFunction
}) => {
  const [isOpen, setIsOpen] = (0, import_react6.useState)(false);
  const [inputValue, setInputValue] = (0, import_react6.useState)();
  const countryLabels = countryTranslations_default;
  const getCountryLabelsList = (country) => Object.keys(countryLabels).reduce((acc, label) => {
    if (label === country) {
      return { label: countryLabels[label], value: label };
    }
    return acc;
  }, {});
  const countries = options.map((country) => {
    const callingCode = (0, import_react_phone_number_input.getCountryCallingCode)(country);
    const countryLabel = getCountryLabelsList(country);
    return {
      value: country,
      countryLabel: countryLabel?.label,
      callingCode
    };
  });
  const [selectedCountryCallingCode, setSelectedCountryCallingCode] = (0, import_react6.useState)(
    () => {
      if (selected) {
        return countries.find((country) => country.value === selected)?.callingCode;
      }
      return "";
    }
  );
  const handleChange = (country) => {
    setSelectedCountryCallingCode(country.callingCode);
    onChange(country.value);
  };
  const sortedCountries = countries.sort((ca, cb) => ca.countryLabel?.localeCompare(cb.countryLabel)).filter((country) => !!country.countryLabel);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("div", { className: "relative text-black transition-all", children: [
    /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
      "button",
      {
        className: "w-23 input border-accent focus-input flex items-center rounded-lg border-2 pl-4 pr-8",
        onClick: (e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        },
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("span", { className: "font-normal", children: [
            "+",
            selectedCountryCallingCode
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
            "div",
            {
              className: (0, import_classnames4.default)(
                { "rotate-180": isOpen },
                "absolute inset-y-0 right-1 flex items-center transition-all"
              ),
              children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_theseus2.Icon, { name: "ChevronDown", iconSize: "sm" })
            }
          )
        ]
      }
    ),
    isOpen ? /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
      DropDown,
      {
        countries: sortedCountries,
        onInputChange: setInputValue,
        onChange: handleChange,
        selected,
        inputValue,
        closeList: () => setIsOpen(false),
        i18nFunction
      }
    ) : null
  ] });
};
var DropDown = ({
  countries,
  selected,
  inputValue,
  onChange,
  onInputChange,
  closeList,
  i18nFunction
}) => {
  const fuse = new import_fuse.default(countries, {
    keys: ["countryLabel", "value", "callingCode"]
  });
  const t = i18nFunction;
  const result = !!inputValue ? fuse.search(inputValue).map((val) => val.item) : countries;
  const handleChange = (country) => {
    onChange(country);
    closeList();
  };
  const handleInputChange = (e) => {
    onInputChange(e.currentTarget.value);
  };
  (0, import_shared.useEventListener)("keydown", (e) => {
    if (e.key === "Escape") {
      closeList();
    }
  });
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
    "div",
    {
      className: (0, import_classnames4.default)(
        "md:max-h-100 divide-primary/20 absolute -bottom-2 left-0 z-10 flex max-h-80 min-w-[280x] translate-y-full flex-col divide-y-2 overflow-scroll rounded-lg bg-white shadow-md transition-all md:min-w-[400px]"
      ),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", { className: "p-4", children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
          "input",
          {
            type: "search",
            placeholder: t(
              "steps.personal_details.input.placeholder.country_select"
            ),
            onChange: handleInputChange,
            className: "input border-accent input-sm focus:ring-accent w-full rounded-md border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white"
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", { children: result.map((country) => /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
          "button",
          {
            className: (0, import_classnames4.default)(
              { "bg-accent": selected === country.value },
              "hover:bg-accent focus:outline-accent flex w-full cursor-pointer justify-between px-8 py-2 focus:outline-2"
            ),
            onClick: () => handleChange(country),
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("span", { children: country.countryLabel }),
              /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("span", { className: "opacity-70", children: [
                "+",
                country.callingCode
              ] })
            ]
          },
          country.value
        )) })
      ]
    }
  );
};
var CountrySelect_default = CountrySelect;

// src/components/CustomPhoneInput/CustomPhoneInput.tsx
var import_jsx_runtime10 = require("react/jsx-runtime");
function CustomPhoneInput({
  label,
  defaultCountry,
  name,
  value,
  onChange,
  onBlur,
  selectedCountry,
  changeSelectedCountry,
  isDirty,
  errors,
  i18nFunction
}) {
  const phoneInputRef = (0, import_react7.useRef)(null);
  const countries = (0, import_react_phone_number_input2.getCountries)();
  const changeCountry = (country) => {
    changeSelectedCountry(country);
    phoneInputRef.current?.focus();
  };
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", { className: "flex w-full flex-col gap-2", children: [
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("label", { htmlFor: name, children: label }),
    /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", { className: "flex flex-wrap gap-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
        CountrySelect_default,
        {
          name,
          selected: selectedCountry,
          options: countries,
          onChange: changeCountry,
          i18nFunction
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", { className: "relative w-full flex-1 gap-1", children: [
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
          import_input.default,
          {
            name,
            defaultCountry,
            country: selectedCountry,
            className: (0, import_classnames5.default)(
              { "focus-input-error": errors?.message },
              "input border-accent focus-input w-full rounded-lg border-2 text-black focus:ring-offset-white"
            ),
            value,
            onChange,
            ref: phoneInputRef,
            onBlur,
            "data-testid": "phone"
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(InputError, { isDirty, errors })
      ] }),
      errors?.message && /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("div", { className: "w-full rounded-lg bg-[#FFE2E0] p-3 text-xs leading-6 text-black", children: errors?.message })
    ] })
  ] });
}
CustomPhoneInput.displayName = "CustomPhoneInput";

// src/components/DateButton.tsx
var import_shared2 = require("@booking/shared");
var import_types2 = __toESM(require_dist());
var import_class_variance_authority = require("class-variance-authority");
var import_locale = require("date-fns/locale");
var import_date_fns_tz = require("date-fns-tz");
var import_router = require("next/router");

// src/components/Tooltip.tsx
var import_react8 = require("@floating-ui/react");
var import_react9 = require("react");
var import_jsx_runtime11 = require("react/jsx-runtime");
function useTooltip({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  isAvailable
} = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = (0, import_react9.useState)(initialOpen);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = (0, import_react9.useRef)(null);
  const data = (0, import_react8.useFloating)({
    placement,
    open,
    onOpenChange: (open2) => !isAvailable && setOpen(open2),
    whileElementsMounted: import_react8.autoUpdate,
    middleware: [
      (0, import_react8.offset)(10),
      (0, import_react8.flip)(),
      (0, import_react8.shift)({ padding: 20 }),
      (0, import_react8.arrow)({ element: arrowRef })
    ]
  });
  const context = data.context;
  const hover = (0, import_react8.useHover)(context, {
    move: false,
    enabled: controlledOpen == null
  });
  const focus = (0, import_react8.useFocus)(context, {
    enabled: controlledOpen == null
  });
  const dismiss = (0, import_react8.useDismiss)(context);
  const role = (0, import_react8.useRole)(context, { role: "tooltip" });
  const interactions = (0, import_react8.useInteractions)([hover, focus, dismiss, role]);
  const transitionStyles = (0, import_react8.useTransitionStyles)(context, {
    common: {
      transformOrigin: "top"
    },
    initial: {
      opacity: 0
    },
    open: {
      opacity: 1
    },
    duration: 100
  });
  return (0, import_react9.useMemo)(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
      ...transitionStyles
    }),
    [open, setOpen, interactions, transitionStyles, data, arrowRef]
  );
}
var TooltipContext = (0, import_react9.createContext)(null);
var useTooltipContext = () => {
  const context = (0, import_react9.useContext)(TooltipContext);
  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }
  return context;
};
function Tooltip({
  children,
  ...options
}) {
  const tooltip = useTooltip(options);
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(TooltipContext.Provider, { value: tooltip, children });
}
var TooltipTrigger = (0, import_react9.forwardRef)(function TooltipTrigger2({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = (0, import_react8.useMergeRefs)([context.refs.setReference, propRef, childrenRef]);
  if (asChild && (0, import_react9.isValidElement)(children)) {
    return (0, import_react9.cloneElement)(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed"
      })
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
    "button",
    {
      ref,
      "data-state": context.open ? "open" : "closed",
      ...context.getReferenceProps(props),
      children
    }
  );
});
var TooltipContent = (0, import_react9.forwardRef)(function TooltipContent2({ children, style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = (0, import_react8.useMergeRefs)([
    context.refs.setFloating,
    context.arrowRef,
    propRef
  ]);
  if (!context.open)
    return null;
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(import_react8.FloatingPortal, { children: /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)(
    "div",
    {
      ref,
      style: {
        ...context.styles,
        ...context.floatingStyles,
        ...style
      },
      ...context.getFloatingProps(props),
      children: [
        children,
        /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
          import_react8.FloatingArrow,
          {
            ref: context.arrowRef,
            context: context.context,
            className: "fill-white",
            tipRadius: 2,
            height: 8
          }
        )
      ]
    }
  ) });
});

// src/components/DateButton.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
function DateButton({
  day,
  selectedDay,
  className,
  isAvailable,
  onClick,
  i18nFunction,
  trackingEvent
}) {
  const dateFnsLocale = (0, import_router.useRouter)().locale === "en" ? import_locale.enGB : import_locale.nb;
  const t = i18nFunction;
  const isDisabled = !isAvailable;
  const [dayNumber, dayName, monthName] = (0, import_date_fns_tz.formatInTimeZone)(
    (0, import_shared2.parseDay)(day),
    import_shared2.timeZone,
    "dd-E-MMM",
    { locale: dateFnsLocale }
  ).split("-");
  const isSelected = day === selectedDay;
  const isToday = (0, import_shared2.getDateInTimezone)() === day;
  const buttonStyle = (0, import_class_variance_authority.cva)(["w-full", className], {
    variants: {
      intent: {
        selected: [
          "selected",
          "bg-accent",
          "ring-offset-primary",
          "ring-2",
          "text-black",
          "ring-offset-2",
          "outline-none",
          "ring-accent"
        ],
        disabled: ["bg-black/20", "text-white"],
        default: ["bg-white", "text-black", "hover:bg-accent"]
      }
    }
  });
  const handleClick = () => {
    if (isDisabled) {
      trackingEvent(import_types2.Actions.SelectDateButtonDisabled, day);
      return;
    }
    onClick(day);
  };
  let testId = "date-button";
  if (isSelected) {
    testId = "date-button-selected";
  } else if (isDisabled) {
    testId = "date-button-disabled";
  }
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(Tooltip, { isAvailable, children: [
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(TooltipTrigger, { asChild: true, children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(
      "button",
      {
        id: `date-button-${day}`,
        onClick: handleClick,
        className: buttonStyle({
          intent: isSelected ? "selected" : isDisabled ? "disabled" : "default"
        }),
        "aria-disabled": isDisabled ? "true" : void 0,
        "data-testid": testId,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "text-[15px] leading-5", children: isToday ? t("today") : dayName }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "font-medium leading-5", children: dayNumber }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "text-[15px] leading-5", children: monthName })
        ]
      },
      day
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(TooltipContent, { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "w-fit-content h-auto max-w-[120px] rounded-md bg-white p-2 text-center text-xs text-black shadow-md", children: t("steps.select_datetime.tooltip.date.no_available", {
      ns: "booking"
    }) }) })
  ] });
}

// src/components/DateScrollButtons.tsx
var import_classnames6 = __toESM(require("classnames"));
var import_fi = require("react-icons/fi");
var import_jsx_runtime13 = require("react/jsx-runtime");
function DateButtonsScroll({
  forward,
  backward,
  canGoBack
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)("div", { className: "mt-2 flex items-center justify-center rounded-lg", children: [
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
      "button",
      {
        onClick: backward,
        className: (0, import_classnames6.default)(
          {
            "btn-disabled bg-white/20": !canGoBack,
            "bg-white": canGoBack
          },
          "hover:bg-accent rounded-lg rounded-r-none border-none p-2 transition-all duration-200"
        ),
        disabled: !canGoBack,
        "data-testid": "date-scroll-backward",
        children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_fi.FiArrowLeft, { className: "stroke-primary", size: 24 })
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
      "button",
      {
        onClick: forward,
        className: (0, import_classnames6.default)(
          "hover:bg-accent rounded-lg rounded-l-none border-none bg-white p-2 transition-all duration-200"
        ),
        "data-testid": "date-scroll-forward",
        children: /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_fi.FiArrowRight, { className: "stroke-primary", size: 24 })
      }
    )
  ] });
}

// src/components/DateSkeleton.tsx
var import_jsx_runtime14 = require("react/jsx-runtime");
function DateSkeleton({ daysToList }) {
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("div", { className: "relative flex w-full justify-start gap-2", children: Array(daysToList).fill(null).map(() => null) });
}

// src/components/Divider.tsx
var import_classnames7 = __toESM(require("classnames"));
var import_jsx_runtime15 = require("react/jsx-runtime");
function Divider({ text, className }) {
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
    "div",
    {
      className: (0, import_classnames7.default)(
        "flex h-1 w-full justify-center rounded-full bg-white/20",
        className
      ),
      children: text ? /* @__PURE__ */ (0, import_jsx_runtime15.jsx)("p", { className: "bg-primary -mt-4 px-2 text-white/60", children: text }) : null
    }
  );
}

// src/components/DrDropinLogo.tsx
var import_jsx_runtime16 = require("react/jsx-runtime");
function DrDropinLogo() {
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)("svg", { width: "123", height: "46", fill: "#fff", xmlns: "http://www.w3.org/2000/svg", children: [
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M107.175 0c-3.418 0-6.202 2.8-6.202 6.237V9.64h-3.42c-3.418 0-6.202 2.8-6.202 6.238a.95.95 0 0 0 .952.957.95.95 0 0 0 .951-.957c0-2.375 1.939-4.324 4.3-4.324h4.335a.95.95 0 0 0 .951-.957V6.237c0-2.374 1.939-4.323 4.3-4.323 2.362 0 4.3 1.949 4.3 4.323v4.36a.95.95 0 1 0 1.903 0v-4.36A6.177 6.177 0 0 0 107.175 0z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
      "path",
      {
        d: "M122.048 14.955a.95.95 0 0 0-.951.957c0 2.375-1.939 4.324-4.3 4.324h-4.335a.95.95 0 0 0-.952.957v4.359c0 2.374-1.938 4.323-4.299 4.323-2.362 0-4.3-1.949-4.3-4.323v-4.36a.95.95 0 1 0-1.903 0v4.36c0 3.437 2.784 6.237 6.203 6.237 3.418 0 6.203-2.8 6.203-6.237v-3.367h3.383c3.419 0 6.203-2.8 6.203-6.237 0-.567-.423-.993-.952-.993z",
        style: {
          fill: "var(--bg-secondary)"
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M4.3 26.012H.916a.936.936 0 0 0-.916.922v14.14c0 .496.423.922.916.922H4.23c5.075 0 8.106-2.977 8.106-7.939v-.177c.035-4.926-2.995-7.868-8.035-7.868zm-.106 1.879c4.828 0 5.85 3.26 5.85 5.953v.178c0 3.898-2.079 6.06-5.815 6.06H2.291V27.89h1.903z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M19.525 30.123c-1.304.248-2.044.993-2.538 1.701v-.531c0-.603-.493-1.099-1.092-1.099-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098s1.092-.496 1.092-1.099v-5.387c0-2.161.81-3.154 2.855-3.402.494-.07.881-.496.881-1.027 0-.32-.14-.603-.352-.78-.247-.177-.564-.284-.846-.213z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M22.873 38.948c-.881 0-1.586.709-1.586 1.594 0 .886.705 1.595 1.586 1.595.881 0 1.586-.709 1.586-1.595s-.705-1.594-1.586-1.594z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M31.825 26.012h-3.383a.936.936 0 0 0-.917.922v14.14c0 .496.423.922.917.922h3.313c5.075 0 8.106-2.977 8.106-7.939v-.177c0-4.926-2.996-7.868-8.036-7.868zm-.14 1.879c4.828 0 5.85 3.26 5.85 5.953v.178c0 3.898-2.08 6.06-5.816 6.06h-1.938V27.89h1.903z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M47.015 30.123c-1.304.248-2.044.993-2.537 1.701v-.531c0-.603-.494-1.099-1.093-1.099-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098.6 0 1.093-.496 1.093-1.099v-5.387c0-2.161.81-3.154 2.854-3.402.494-.07.881-.496.881-1.027 0-.32-.14-.603-.352-.78-.247-.213-.564-.284-.846-.213z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M54.874 30.017c-3.348 0-5.78 2.516-5.78 6.025v.177c0 3.473 2.432 5.99 5.745 5.99 3.348 0 5.78-2.517 5.78-6.026v-.177c.035-3.473-2.397-5.989-5.745-5.989zm3.524 6.025v.142c0 2.516-1.374 4.146-3.56 4.146-2.184 0-3.559-1.595-3.559-4.146v-.178c0-2.551 1.375-4.146 3.56-4.146 2.185.035 3.56 1.666 3.56 4.182z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M68.725 30.017c-1.515 0-2.89.815-3.665 1.737v-.461c0-.603-.494-1.099-1.093-1.099-.6 0-1.093.496-1.093 1.099V44.9c0 .603.494 1.1 1.093 1.1.6 0 1.093-.497 1.093-1.1v-4.359c.846 1.064 2.15 1.666 3.665 1.666 3.207 0 5.357-2.445 5.357-6.095v-.178c0-3.544-2.15-5.918-5.357-5.918zm3.101 6.025v.177c0 1.878-.563 4.146-3.313 4.146-2.29 0-3.56-1.453-3.56-4.146v-.177c0-2.587 1.34-4.147 3.56-4.147 2.01 0 3.313 1.63 3.313 4.147z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M77.43 30.194c-.6 0-1.093.496-1.093 1.099v9.64c0 .602.494 1.098 1.093 1.098.6 0 1.092-.496 1.092-1.099v-9.64c0-.602-.493-1.098-1.092-1.098z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M77.395 25.41c-.74 0-1.374.638-1.374 1.382 0 .744.634 1.382 1.374 1.382.74 0 1.374-.638 1.374-1.382 0-.744-.634-1.382-1.374-1.382z" }),
    /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("path", { d: "M87.263 30.017c-1.692 0-2.855.744-3.489 1.56v-.284c0-.603-.493-1.099-1.093-1.099-.599 0-1.092.496-1.092 1.099v9.64c0 .602.493 1.098 1.092 1.098.6 0 1.093-.496 1.093-1.099v-6.024c0-2.02 1.515-2.942 2.996-2.942 1.762 0 2.502.815 2.502 2.73v6.236c0 .603.493 1.099 1.093 1.099.599 0 1.092-.496 1.092-1.099v-6.13c0-3.97-2.29-4.785-4.194-4.785z" })
  ] });
}

// src/components/HighlightedIcon.tsx
var import_theseus3 = require("@drdropin-tech/theseus");
var import_classnames8 = __toESM(require("classnames"));
var import_jsx_runtime17 = require("react/jsx-runtime");
function HighlightedIcon({
  shouldHighlight,
  icon,
  className
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
    "span",
    {
      className: (0, import_classnames8.default)(
        {
          "bg-black/12 transition-all": shouldHighlight
        },
        "rounded-full p-1",
        className
      ),
      children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_theseus3.Icon, { name: icon, iconSize: "md" })
    }
  );
}

// src/components/Link.tsx
var import_theseus4 = require("@drdropin-tech/theseus");
var import_link = __toESM(require("next/link"));
var import_jsx_runtime18 = require("react/jsx-runtime");
function Link({
  as = "link",
  buttonProps,
  to = "",
  rel = "norefferer",
  className,
  children,
  disabled,
  isExternalLink,
  locale = "nb"
}) {
  if (disabled) {
    return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)("div", { className: `${className} pointer-events-none`, children });
  }
  const isOutsideOfNext = to?.includes("https") || isExternalLink;
  if (isOutsideOfNext) {
    return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      import_theseus4.Link,
      {
        href: to,
        as,
        title: `Link to: ${to}`,
        className,
        target: "_blank",
        rel,
        children
      }
    );
  }
  const localizedPrefix = locale === "nb" ? "" : "/en";
  const href = `${localizedPrefix}${to}`;
  const btnProps = as === "button" ? buttonProps : {};
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_link.default, { passHref: true, href, locale, children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(import_theseus4.Link, { as, className, ...btnProps, children }) });
}

// src/components/LocaleSwitcher.tsx
var import_theseus5 = require("@drdropin-tech/theseus");
var import_router2 = require("next/router");
var import_react10 = require("react");
var import_jsx_runtime19 = require("react/jsx-runtime");
function LocaleSwitcher({
  locales,
  translation
}) {
  const router = (0, import_router2.useRouter)();
  const [isOpen, setIsOpen] = (0, import_react10.useState)(false);
  const ref = (0, import_react10.useRef)(null);
  (0, import_react10.useEffect)(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    });
    return () => {
      document.removeEventListener("click", () => {
      });
    };
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)("div", { ref, children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
      import_theseus5.Icon,
      {
        role: "button",
        tabIndex: 1,
        "aria-label": translation("navigation.switch_language"),
        name: "Globe",
        onClick: () => setIsOpen(!isOpen),
        className: "cursor-pointer"
      }
    ),
    isOpen && /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)(import_jsx_runtime19.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
        import_theseus5.Icon,
        {
          name: "Play",
          fill: "white",
          className: "absolute top-8 z-10 rotate-[-90deg]"
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("div", { className: "absolute right-0 top-12 flex flex-col items-center justify-center rounded-lg bg-white p-2 shadow-sm shadow-inherit", children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("ul", { children: locales?.map((locale) => /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("li", { children: /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
        Link,
        {
          locale: locale.locale,
          to: router.asPath,
          className: "text-black no-underline",
          children: /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)("div", { className: "flex flex-row justify-between gap-3 p-2", children: [
            router.locale === "nb" ? locale.labels.nb : locale.labels.en,
            locale.locale === router.locale && /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(import_theseus5.Icon, { name: "Check", className: "relative top-1 ml-2" })
          ] })
        }
      ) }, locale.locale)) }) })
    ] })
  ] });
}

// src/components/Map.tsx
var import_react_wrapper = require("@googlemaps/react-wrapper");
var import_react11 = __toESM(require("react"));
var import_jsx_runtime20 = require("react/jsx-runtime");
function Map({
  coordinates,
  name,
  street,
  postalCode,
  city,
  trackingFunction,
  i18nFunction
}) {
  const [zoom] = (0, import_react11.useState)(15);
  const [center] = (0, import_react11.useState)(coordinates);
  const t = i18nFunction;
  const trackClick = () => {
    if (trackingFunction) {
      trackingFunction("web_booking_pickDateTime_clickedOpenMap");
    }
  };
  const query = encodeURIComponent(
    `Dr. Dropin ${name}, ${street}, ${postalCode} ${city}`
  );
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)("div", { className: "relative flex h-full", children: [
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(import_react_wrapper.Wrapper, { apiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`, children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      MapElement,
      {
        center,
        zoom,
        disableDefaultUI: true,
        className: "h-50 flex-grow rounded-lg",
        children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(Marker, { position: coordinates })
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)("div", { className: "absolute bottom-5 left-5 cursor-pointer no-underline", children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      "a",
      {
        target: "_blank",
        rel: "noopener noreferrer",
        href: `https://maps.google.com/?q=${query}`,
        onClick: trackClick,
        children: /* @__PURE__ */ (0, import_jsx_runtime20.jsx)("span", { className: "border-primary text-primary rounded-full border bg-white px-4 py-2 text-base leading-4 no-underline", children: t("map.button_text", { ns: "common" }) })
      }
    ) })
  ] });
}
var MapElement = ({ children, style, className, ...options }) => {
  const ref = (0, import_react11.useRef)(null);
  const [map, setMap] = (0, import_react11.useState)();
  (0, import_react11.useEffect)(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {});
      newMap.setOptions(options);
      setMap(newMap);
    }
  }, [ref, map, options]);
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_jsx_runtime20.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)("div", { ref, style, className }),
    import_react11.default.Children.map(children, (child) => {
      if ((0, import_react11.isValidElement)(child)) {
        return (0, import_react11.cloneElement)(child, { map });
      }
      return child;
    })
  ] });
};
var Marker = (options) => {
  const [marker, setMarker] = (0, import_react11.useState)();
  (0, import_react11.useEffect)(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker(options);
      setMarker(newMarker);
      return () => {
        newMarker.setMap(null);
      };
    } else {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

// src/components/Notification.tsx
var import_shared3 = require("@booking/shared");
var import_theseus6 = require("@drdropin-tech/theseus");
var import_framer_motion3 = require("framer-motion");
var import_jsx_runtime21 = require("react/jsx-runtime");
var variants = {
  visible: { opacity: 1, y: "0%", transition: { duration: 0.3 } },
  hidden: { opacity: 0, y: "100%" }
};
function Notification({
  i18nFunction
}) {
  const t = i18nFunction;
  const { isNotificationVisible, closeNotification } = (0, import_shared3.useNotificationStore)(
    (state) => ({
      isNotificationVisible: state.isNotificationVisible,
      closeNotification: state.closeNotification
    })
  );
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
    import_framer_motion3.motion.aside,
    {
      variants,
      initial: "hidden",
      animate: isNotificationVisible ? "visible" : "hidden",
      className: "fixed bottom-0 z-10 m-0 flex w-full justify-center",
      children: /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)("div", { className: "relative flex justify-center rounded-t-lg bg-white py-3 pl-6 pr-10 text-sm text-black shadow-md", children: [
        /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("span", { children: t("notification.geo_permission") }),
        /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
          "span",
          {
            className: "absolute right-3 top-3 min-w-fit cursor-pointer",
            onClick: closeNotification,
            children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(import_theseus6.Icon, { name: "X", iconSize: "md" })
          }
        )
      ] })
    }
  );
}

// src/components/RightArrowButton.tsx
var import_theseus7 = require("@drdropin-tech/theseus");
var import_jsx_runtime22 = require("react/jsx-runtime");
function RightArrowButton({ text, onClick }) {
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(
    "button",
    {
      className: "mb-8 flex w-full items-center justify-between",
      onClick,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("span", { children: text }),
        /* @__PURE__ */ (0, import_jsx_runtime22.jsx)("div", { className: "text-primary hover:bg-accent rounded-full bg-white p-1", children: /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(import_theseus7.Icon, { name: "ArrowRight" }) })
      ]
    }
  );
}

// src/components/RoundedButton.tsx
var import_jsx_runtime23 = require("react/jsx-runtime");
function RoundedButton({ onClick, title }) {
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    "button",
    {
      className: "flex w-fit rounded-full bg-white px-5 py-1 text-black",
      onClick,
      children: title
    }
  );
}

// src/components/SelectPills.tsx
var import_theseus8 = require("@drdropin-tech/theseus");
var import_classnames9 = __toESM(require("classnames"));
var import_jsx_runtime24 = require("react/jsx-runtime");
function SelectPills({
  options,
  selectedOption,
  onClick,
  isDisabled
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { className: "mb-4 flex w-full items-center gap-2", children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("div", { className: "relative flex gap-2", children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)("ul", { className: "flex gap-2", children: options.map((option, index) => {
    return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(
      "li",
      {
        tabIndex: index,
        className: (0, import_classnames9.default)(
          {
            "bg-accent text-black": option.key === selectedOption.key
          },
          isDisabled && {
            "disabled pointer-events-none opacity-50": isDisabled(
              option.value
            )
          },
          "border-accent flex cursor-pointer snap-start items-center gap-2 rounded-full border px-4 py-2 text-xs"
        ),
        "data-testid": `select-pill-${option.value}`,
        onClick: () => onClick(option),
        children: [
          option.icon && /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(import_theseus8.Icon, { name: option.icon, iconSize: "xs" }),
          option.key
        ]
      },
      option.key
    );
  }) }) }) });
}

// src/components/ServiceCard.tsx
var import_theseus9 = require("@drdropin-tech/theseus");
var import_link2 = __toESM(require("next/link"));
var import_jsx_runtime25 = require("react/jsx-runtime");
function ServiceCard({
  service: { title, description, to, name, services }
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "bg-tertiary grid h-full grid-rows-[auto,1fr,1fr,auto] items-start justify-start gap-3 rounded-md p-6", children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("h2", { className: "prose-2xl", children: title }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("p", { className: "prose-md", children: description }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "flex flex-wrap gap-2", children: services?.map((service) => /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("div", { className: "pill pill-secondary", children: service }, service)) }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_link2.default, { href: to ?? `/booking/${name}`, passHref: true, children: /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      import_theseus9.Link,
      {
        as: "button",
        buttonProps: {
          theme: "dark-contrast",
          buttonSize: "sm"
        },
        children: "Book time"
      }
    ) })
  ] });
}

// src/components/SpinnerWrapper.tsx
var import_theseus10 = require("@drdropin-tech/theseus");
var import_jsx_runtime26 = require("react/jsx-runtime");
var SpinnerWrapper = () => /* @__PURE__ */ (0, import_jsx_runtime26.jsx)("div", { className: "flex justify-center", children: /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(import_theseus10.Spinner, { size: "xl" }) });
var SpinnerWrapper_default = SpinnerWrapper;

// src/components/StepButton.tsx
var import_classnames10 = __toESM(require("classnames"));
var import_framer_motion4 = require("framer-motion");
var import_jsx_runtime27 = require("react/jsx-runtime");
function StepButton({
  onClick,
  title,
  children,
  className,
  description,
  imageSrc,
  TitleDecorator,
  DescriptionDecorator
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)(
    "button",
    {
      className: (0, import_classnames10.default)(
        "hover:bg-accent flex w-full flex-col justify-between overflow-hidden rounded-xl bg-white px-6 py-4 text-left text-black",
        className
      ),
      "data-testid": "step-button",
      onClick,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex w-full flex-row justify-between gap-3", children: [
          imageSrc ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
            "img",
            {
              src: imageSrc,
              alt: "",
              className: "h-20 w-20 rounded-full object-cover"
            }
          ) : null,
          /* @__PURE__ */ (0, import_jsx_runtime27.jsxs)("div", { className: "flex w-full flex-col md:text-base", children: [
            typeof title === "string" ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("p", { className: "text-sm font-normal leading-[28px] md:leading-[32px]", children: title }) : title,
            description ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "flex items-center text-xs leading-[24px] text-black/50", children: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("span", { children: description }) }) : null,
            DescriptionDecorator ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "flex items-center justify-between whitespace-nowrap text-xs leading-[24px]", children: DescriptionDecorator }) : null
          ] }),
          TitleDecorator ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)("div", { className: "flex items-center justify-between whitespace-nowrap text-xs leading-[24px]", children: TitleDecorator }) : null
        ] }),
        children
      ]
    }
  );
}
var getAnimationStyle = (i) => ({
  hidden: { opacity: 0, translateY: "5%" },
  show: {
    opacity: 1,
    translateY: "0%",
    transition: {
      delay: i * 0.07,
      duration: 0.3
    }
  }
});
function AnimatedStep({ children, order, className }) {
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
    import_framer_motion4.motion.div,
    {
      variants: getAnimationStyle(order),
      initial: "hidden",
      animate: "show",
      layout: true,
      className: (0, import_classnames10.default)("w-full", className),
      children
    }
  );
}

// src/components/StepListItem.tsx
var import_classnames11 = __toESM(require("classnames"));
var import_framer_motion5 = require("framer-motion");
var import_jsx_runtime28 = require("react/jsx-runtime");
var stepVariant = {
  hide: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0 }
};
function StepListItem({
  children,
  className,
  disabled,
  onClick
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(
    import_framer_motion5.motion.button,
    {
      variants: stepVariant,
      disabled,
      className: (0, import_classnames11.default)(
        "hover:bg-accent flex w-full cursor-pointer items-center justify-between gap-1 px-6 py-5 transition-all",
        className
      ),
      onClick,
      "data-testid": "step-list-item",
      children
    }
  );
}

// src/components/StepWrapper.tsx
var import_classnames12 = __toESM(require("classnames"));
var import_image2 = __toESM(require("next/image"));
var import_jsx_runtime29 = require("react/jsx-runtime");
function StepWrapper({
  title,
  subTitle,
  imageSrc,
  isLoading,
  className,
  stepWidth,
  children
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(
    "div",
    {
      className: (0, import_classnames12.default)(
        "m-auto mb-20 flex flex-col items-center justify-center",
        stepWidth
      ),
      children: [
        imageSrc && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("div", { className: "mb-8", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
          import_image2.default,
          {
            src: imageSrc,
            width: 112,
            height: 112,
            alt: "Step image",
            style: {
              maxWidth: "100%",
              height: "auto",
              objectFit: "cover"
            }
          }
        ) }),
        /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)("div", { className: "prose prose-h2:text-white mb-10 text-center", children: [
          title && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("h2", { className: "leading-[44px] md:text-[40px] lg:leading-[56px]", children: title }),
          subTitle && /* @__PURE__ */ (0, import_jsx_runtime29.jsx)("p", { className: "text-xl font-normal leading-7", children: subTitle })
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
          "div",
          {
            className: (0, import_classnames12.default)(
              "flex w-full flex-col items-center justify-center gap-2",
              className
            ),
            children: isLoading ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(SpinnerWrapper_default, {}) : children
          }
        )
      ]
    }
  );
}

// src/components/TermsOfService.tsx
var import_theseus12 = require("@drdropin-tech/theseus");

// src/providers/ModalProvider.tsx
var import_theseus11 = require("@drdropin-tech/theseus");
var import_react12 = require("@headlessui/react");
var import_classnames13 = __toESM(require("classnames"));
var import_framer_motion6 = require("framer-motion");
var import_image3 = __toESM(require("next/image"));
var import_react13 = require("react");
var import_jsx_runtime30 = require("react/jsx-runtime");
var import_react14 = require("react");
var AnimatedDialogOverlay = (0, import_framer_motion6.motion)(import_react12.Dialog.Overlay);
var AnimatedDialogPanel = (0, import_framer_motion6.motion)(import_react12.Dialog.Panel);
var Modal = ({
  isOpen = true,
  title,
  description,
  children,
  closeModal,
  vertical,
  imageSrc,
  iframeSrc
}) => {
  (0, import_react13.useEffect)(() => {
    const bind = (e) => {
      if (e.code !== "27") {
        return;
      }
      if (document.activeElement && ["INPUT", "SELECT"].includes(document.activeElement.tagName)) {
        return;
      }
      closeModal();
    };
    document.addEventListener("keyup", bind);
    return () => document.removeEventListener("keyup", bind);
  }, [closeModal]);
  if (!title && !description && !children) {
    title = "Empty modal";
    description = "Maybe you should add some content?";
    children = /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { children: "Empty Modal" });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_framer_motion6.AnimatePresence, { children: isOpen ? /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
    import_react12.Dialog,
    {
      as: "div",
      className: (0, import_classnames13.default)("fixed inset-0 z-10 overflow-y-auto", vertical),
      onClose: closeModal,
      open: isOpen,
      children: /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)("div", { className: "flex min-h-screen items-center justify-center", children: [
        /* @__PURE__ */ (0, import_react14.createElement)(
          AnimatedDialogOverlay,
          {
            ...overlayAnimation,
            key: "overlay",
            className: "fixed inset-0 bg-black/50"
          }
        ),
        /* @__PURE__ */ (0, import_react14.createElement)(
          AnimatedDialogPanel,
          {
            ...modalAnimation,
            key: "modal",
            className: "prose prose-img:m-0 lg:py-17 relative z-[10000] flex h-1/2 w-full max-w-lg flex-col rounded-xl bg-white p-8 shadow-xl lg:px-16"
          },
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            "button",
            {
              onClick: closeModal,
              className: "absolute right-8 top-8 cursor-pointer rounded-full bg-black/10 p-2",
              children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_theseus11.Icon, { name: "X", appearance: "primary" })
            }
          ),
          imageSrc && /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { className: "w-30 h-30 my-4 self-center", children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            import_image3.default,
            {
              src: imageSrc,
              alt: "Please",
              height: 120,
              width: 120,
              className: "mb-4 mt-4 self-center",
              style: {
                maxWidth: "100%",
                height: "auto"
              }
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            import_react12.Dialog.Title,
            {
              as: "h2",
              className: "m-5 break-words text-center text-3xl",
              children: title ? /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("span", { dangerouslySetInnerHTML: { __html: title } }) : null
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            import_react12.Dialog.Description,
            {
              as: "p",
              className: "list-inside whitespace-pre-line text-sm",
              children: description ? /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("span", { dangerouslySetInnerHTML: { __html: description } }) : null
            }
          ),
          iframeSrc && /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
            "iframe",
            {
              src: iframeSrc,
              className: "h-full w-full",
              style: iframeStyle
            }
          ),
          children
        )
      ] })
    }
  ) : null });
};
var ModalContext = (0, import_react13.createContext)({});
var ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = (0, import_react13.useState)(false);
  const [modalOnClose, setModalOnClose] = (0, import_react13.useState)(null);
  const [modalContent, setModalContent] = (0, import_react13.useState)({});
  const closeModal = (0, import_react13.useCallback)(() => {
    modalOnClose && modalOnClose();
    setModalOnClose(null);
    setIsModalOpen(false);
  }, [modalOnClose]);
  const openModal = (0, import_react13.useCallback)(({ onOpen, onClose, content }) => {
    content && setModalContent(content);
    setIsModalOpen(true);
    onOpen && onOpen();
    onClose && setModalOnClose(onClose);
  }, []);
  const contextValues = (0, import_react13.useMemo)(
    () => ({ isModalOpen, openModal, closeModal }),
    [closeModal, isModalOpen, openModal]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(ModalContext.Provider, { value: contextValues, children: [
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(Modal, { closeModal, isOpen: isModalOpen, ...modalContent }),
    children
  ] });
};
var useModal = () => {
  const context = (0, import_react13.useContext)(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
var modalAnimation = {
  initial: {
    opacity: 0,
    y: 0,
    scale: 0.95
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1
  },
  exit: {
    opacity: 0,
    y: 0,
    scale: 0.95
  },
  transition: {
    ease: "easeInOut",
    duration: 0.2
  }
};
var overlayAnimation = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  },
  transition: {
    ease: "easeInOut",
    duration: 0.2
  }
};
var iframeStyle = {
  minHeight: "60vh"
};

// src/components/TermsOfService.tsx
var import_jsx_runtime31 = require("react/jsx-runtime");
function TermsOfService(props) {
  const t = props.i18nFunction;
  const { openModal } = useModal();
  function toggleModal() {
    openModal({
      content: {
        title: t("terms.modal_title"),
        iframeSrc: t("terms.modal_content")
      }
    });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { className: "rounded-lg bg-black/20 px-4 py-6", children: [
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("p", { className: "text-sm", children: t("terms.accept_text") }),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("br", {}),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("u", { onClick: toggleModal, children: t("terms.terms") }),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
      import_theseus12.CheckBox,
      {
        label: t("terms.accept"),
        checked: props.checked,
        onChange: () => props.onChange(!props.checked),
        theme: "general",
        classname: "border-white"
      }
    )
  ] });
}

// src/components/TextButton.tsx
var import_jsx_runtime32 = require("react/jsx-runtime");
function TextButton({ onClick, text }) {
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    "div",
    {
      className: "cursor-pointer text-sm underline lg:text-[17px]",
      onClick,
      children: text
    }
  );
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Accordion,
  Alert,
  AnimatedStep,
  AppLinks,
  ButtonBadge,
  CustomPhoneInput,
  DateButton,
  DateScrollButtons,
  DateSkeleton,
  Divider,
  DrDropinLogo,
  HighlightedIcon,
  InputError,
  Link,
  LocaleSwitcher,
  Map,
  ModalProvider,
  Notification,
  RightArrowButton,
  RoundedButton,
  SelectPills,
  ServiceCard,
  SpinnerWrapper,
  StepButton,
  StepListItem,
  StepWrapper,
  TermsOfService,
  TextButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useModal
});
